exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-dashboard-js": () => import("./../../../src/pages/admin/dashboard.js" /* webpackChunkName: "component---src-pages-admin-dashboard-js" */),
  "component---src-pages-call-status-js": () => import("./../../../src/pages/call-status.js" /* webpackChunkName: "component---src-pages-call-status-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentors-js": () => import("./../../../src/pages/mentors.js" /* webpackChunkName: "component---src-pages-mentors-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-step-1-js": () => import("./../../../src/pages/step1.js" /* webpackChunkName: "component---src-pages-step-1-js" */),
  "component---src-pages-step-2-js": () => import("./../../../src/pages/step2.js" /* webpackChunkName: "component---src-pages-step-2-js" */),
  "component---src-pages-submission-success-js": () => import("./../../../src/pages/submission-success.js" /* webpackChunkName: "component---src-pages-submission-success-js" */),
  "component---src-pages-ua-404-js": () => import("./../../../src/pages/ua/404.js" /* webpackChunkName: "component---src-pages-ua-404-js" */),
  "component---src-pages-ua-about-js": () => import("./../../../src/pages/ua/about.js" /* webpackChunkName: "component---src-pages-ua-about-js" */),
  "component---src-pages-ua-call-status-js": () => import("./../../../src/pages/ua/call-status.js" /* webpackChunkName: "component---src-pages-ua-call-status-js" */),
  "component---src-pages-ua-imprint-js": () => import("./../../../src/pages/ua/imprint.js" /* webpackChunkName: "component---src-pages-ua-imprint-js" */),
  "component---src-pages-ua-index-js": () => import("./../../../src/pages/ua/index.js" /* webpackChunkName: "component---src-pages-ua-index-js" */),
  "component---src-pages-ua-mentors-js": () => import("./../../../src/pages/ua/mentors.js" /* webpackChunkName: "component---src-pages-ua-mentors-js" */),
  "component---src-pages-ua-privacy-js": () => import("./../../../src/pages/ua/privacy.js" /* webpackChunkName: "component---src-pages-ua-privacy-js" */),
  "component---src-pages-ua-resources-js": () => import("./../../../src/pages/ua/resources.js" /* webpackChunkName: "component---src-pages-ua-resources-js" */),
  "component---src-pages-ua-step-1-js": () => import("./../../../src/pages/ua/step1.js" /* webpackChunkName: "component---src-pages-ua-step-1-js" */),
  "component---src-pages-ua-step-2-js": () => import("./../../../src/pages/ua/step2.js" /* webpackChunkName: "component---src-pages-ua-step-2-js" */),
  "component---src-pages-ua-submission-success-js": () => import("./../../../src/pages/ua/submission-success.js" /* webpackChunkName: "component---src-pages-ua-submission-success-js" */)
}

